import React from "react";
import { injectIntl } from "react-intl";
import { Glyphicon } from "Elements";

import Spinner from "../components/Spinner";

import requestsActions from "./actions";

import messages from "./messages";

// default : show pending, success and error
// use props hidePending, hideSuccess or hideError to override
// default Messages for pending, success and error
// use props pendingMessage, successMessage and errorMessage to override

class StatusAlert extends React.Component {
  state = {};
  componentDidMount() {
    console.log("StatusAlert didMount");
    this.removeRequest = this.removeRequest.bind(this);
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const { request = {} } = props;
    const { request: oRequest = {} } = oProps;
    if (
    !props.noClose &&
    request.status === "done" &&
    oRequest.status !== "done")
    {
      this.timeout = window.setTimeout(this.removeRequest, 5000);
    }
  }
  removeRequest() {
    const { props } = this;
    const { request = {} } = props;
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    console.log("StatusAlert removeRequest");
    return request.id && request.status === "done" ?
    requestsActions.remove(request.id) :
    Promise.resolve();
  }
  render() {
    const { props } = this;
    const request = props.request || {};
    const { message, err = {} } = request;

    if (!request.status) {
      return null;
    }

    const pendingMessage =
    props.pendingMessage || props.intl.formatMessage(messages.pendingMessage);
    const errorMessage = props.errorMessage ?
    props.errorMessage :
    props.intl.formatMessage(messages.errorMessage);
    const doneMessage =
    props.doneMessage || props.intl.formatMessage(messages.doneMessage);

    let title = request.id;

    if (request.status === "pending") {
      title = pendingMessage;
    }
    if (request.status === "done") {
      title = doneMessage;
    }
    if (request.status === "err") {
      title = errorMessage;
    }

    let show = true;
    if (
    request.status === "pending" && props.hidePending ||
    request.status === "done" && props.hideSuccess ||
    request.status === "err" && props.hideError)
    {
      show = false;
    }
    console.log("StatusAlert render", props);
    return show ?
    <div
      className={
      props.className +
      " alert alert-" + (
      request.status === "pending" ?
      "info" :
      request.status === "err" ?
      "danger" :
      "success") +
      " m-1"}

      style={props.style}>

        <b>
          <span className="mr-1">
            {request.status === "pending" ?
          <Spinner size="lg" /> :

          <Glyphicon
            icon={
            request.status === "done" ? "check" : "exclamation-triangle"}

            size="lg" />}


          </span>
          {title}
        </b>
        &nbsp;
        {!props.noClose && ["done", "err"].indexOf(request.status) >= 0 ?
      <button
        className="close ml-1"
        onClick={(e) => {
          e.preventDefault();
          requestsActions.remove(request.id);
        }}>

            <span>&times;</span>
          </button> :
      null}
        {request.status === "err" ? <div>{err.description}</div> : null}
      </div> :
    null;
  }
}

export default injectIntl(StatusAlert);