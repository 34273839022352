import React from "react";
import { FormattedMessage } from "react-intl";
import queryParser from "../utils/queryParser";

import paymentsActions from "./actions";

class PaymentProcessed extends React.Component {
  componentDidMount() {
    const { props } = this;
    const query = queryParser(props.location.search);
    paymentsActions.get(query.payment_id);
  }
  render() {
    const { props } = this;
    const query = queryParser(props.location.search);
    const { result } = query;
    return (
      <div className="page sep-top-2x">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <h3>
                {result === "true" ?
                <FormattedMessage
                  id="account.payments/process/done"
                  defaultMessage="Payment completed" /> :


                <FormattedMessage
                  id="account.payments/process/canceled"
                  defaultMessage="Payment cancelled" />}


              </h3>
            </div>
          </div>
        </div>
      </div>);

  }
}

export default PaymentProcessed;