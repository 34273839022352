import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { FormGroup, Glyphicon, HelpBlock, Tooltip } from "Elements";

const svgWidth = 400;
const svgHeight = 400;

const messages = defineMessages({
  placeholder: { "id": "marketing.capcha/placeholder", "defaultMessage": "Copy the {color} word" },



  helpBlock: { "id": "marketing.capcha/helpBlock", "defaultMessage": "You must copy the word in {color}" },



  orange: { "id": "marketing.capcha/orange", "defaultMessage": "orange" },



  blue: { "id": "marketing.capcha/blue", "defaultMessage": "blue" },



  green: { "id": "marketing.capcha/green", "defaultMessage": "green" },



  yellow: { "id": "marketing.capcha/yellow", "defaultMessage": "yellow" },



  red: { "id": "marketing.capcha/red", "defaultMessage": "red" },



  cyan: { "id": "marketing.capcha/cyan", "defaultMessage": "cyan" },



  greenLight: { "id": "marketing.capcha/greenLight", "defaultMessage": "light green" },



  pink: { "id": "marketing.capcha/pink", "defaultMessage": "pink" },



  refresh: { "id": "marketing.capcha/refresh", "defaultMessage": "Refresh the image" }



});

class Capcha extends React.Component {
  wordEls = [];
  words = [];
  color = false;
  real = false;
  grid = [];
  state = {};
  constructor(props) {
    super(props);
    this.init();
    for (var y = 0; y < svgHeight; y++) {
      this.grid[y] = [];
      for (var x = 0; x < svgWidth; x++) {
        this.grid[y][x] = true;
      }
    }
  }
  init(forceUpdate = false) {
    const { props } = this;
    const { max = words.length } = props;
    this.words = words.
    sort((a, b) => Math.floor(Math.random() * 3) - 1).
    slice(0, max).
    map((word, i) => {
      return {
        word,
        fontSize: Math.floor(Math.random() * 16) + 12,
        fontWeight: Math.floor(Math.random() * 7) * 100 + 200,
        opacity: Math.random() * 0.5 + 0.5,
        rotate: i % 2 !== 0
      };
    });
    this.color =
    Object.keys(colors)[
    Math.floor(Math.random() * Object.keys(colors).length)];

    this.real = Math.floor(Math.random() * max);
    if (forceUpdate) {
      this.forceUpdate();
    }
  }
  componentDidMount() {
    this.update();
  }
  componentDidUpdate() {
    this.update();
  }
  update() {
    this.wordEls = this.wordEls.map((word, i) => {
      const rect = word.el.getBoundingClientRect();
      // console.log('Capcha update width', rect.width)
      const pos = this.getPos(rect, word.word.rotate);
      return Object.assign({}, word, {
        rect,
        pos
      });
    });
  }
  getPos(rect, rotate) {
    return [svgWidth / 2, svgHeight / 2];
  }
  render() {
    const { props, state } = this;
    // console.log('Capcha render', this.words, this.wordEls, this.grid)
    return (
      <div className="my-3 d-flex flex-column align-items-center">
        <div className="d-flex flex-wrap justify-content-center">
          {this.words.map((word, i) => {
            return (
              <span
                key={i}
                style={{
                  color: i === this.real ? colors[this.color] : "#2f2575",
                  opacity: word.opacity,
                  fontSize: word.fontSize,
                  lineHeight: 1,
                  fontWeight: word.fontWeight,
                  fontFamily: "MysteryTourSans"
                  // transform: word.rotate
                  //   ? 'rotate(90deg)'
                  //   : 'none'
                }}>

                {word.word}
              </span>);

          })}
        </div>
        <FormGroup validationState={props.validationState}>
          <div
            className="input-group mt-3"
            style={{
              maxWidth: 300
            }}>

            <input
              onClick={(e) => e.stopPropagation()}
              placeholder={props.intl.formatMessage(messages.placeholder, {
                color: props.intl.formatMessage(messages[this.color])
              })}
              className={"form-control" + (state.isNotRobot ? " is-valid" : "")}
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                const isNotRobot =
                value.toLowerCase() === this.words[this.real].word;
                props.onChange(isNotRobot);
                this.setState({ isNotRobot });
              }} />

            <div className="input-group-append">
              <Tooltip message={props.intl.formatMessage(messages.refresh)}>
                <button
                  style={{
                    padding: "6px 12px"
                  }}
                  className={
                  "btn btn-outline-" + (
                  props.validationState === "error" ? "danger" : "primary")}

                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.init(true);
                  }}>

                  <Glyphicon glyph="sync" />
                </button>
              </Tooltip>
            </div>
          </div>
          <HelpBlock>
            {props.intl.formatMessage(messages.helpBlock, {
              color: props.intl.formatMessage(messages[this.color])
            })}
          </HelpBlock>
        </FormGroup>
        {/* <svg width={svgWidth} height={svgHeight}>
          <circle cx={svgWidth / 2} cy={svgHeight / 2} r="2"></circle>
          {this.words.map((word, i) => {
           const wordEl = this.wordEls[i] || {}
           const pos = wordEl.pos || []
           console.log('Capcha word render', word, wordEl)
           return <text
             key={i}
             ref={el => {
               this.wordEls[i] = Object.assign({}, wordEl, {
                 word,
                 el
               })
             }}
             x={pos[0]}
             y={pos[1]}
             textAnchor="middle"
             alignmentBaseline="middle"
             transform={word.rotate
             ? 'rotate(90, '+pos[0]+', '+pos[1]+')'
             : false}
             fill={i === this.real
             ? '#f00'
             : '#000'}
             style={{
               color: i === this.real
               ? '#f00'
               : '#000',
               fontSize: word.fontSize,
               fontWeight: word.fontWeight,
               // position: 'absolute',
               // transform: [
               //   word.rotate
               //   ? 'rotate(90deg)'
               //   : false,
               //   // 'translate(' + (pos || [])[0] + 'px, ' + (pos || [])[1] + 'px)'
               // ].filter(f => f).join(' '),
               // transformOrigin: 'center',
               // left: (pos || [])[0],
               // top: (pos || [])[1],
               opacity: word.opacity
             }}
             >
               {word.word}
             </text>
           })}
          </svg> */}
      </div>);

  }
}

export default injectIntl(Capcha);

const words = [
"PCBA",
"Factory",
"PCB",
"Wire",
"Enclosure",
"Engineering",
"Prototype",
"Electronic",
"Components",
"Vias",
"Coating",
"Potting",
"Connector",
"Cable",
"capacitor",
"transistor",
"resistor"].
map((w) => w.toLowerCase());

const colors = {
  orange: "#ee7d51",
  cyan: "#0fafab",
  green: "#00957a",
  greenLight: "#97bf0d",
  blue: "#0077be",
  yellow: "#f1b400",
  red: "#c73534",
  pink: "#c7215d"
  // purple: "#6e2367"
};