import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAuth } from "Auth/Context";

import Spinner from "components/Spinner";
import { Glyphicon } from "Elements";
import Capcha from "components/Capcha";
import { getLocal, setLocal } from "utils/localStorage";

import Thread, { tchatMessages } from "./Thread";

import messagingActions from "./actions";

import "./index.css";

class Messaging extends React.Component {
  state = {};
  componentDidMount() {
    if (this.props.auth.user.id) {
      this.getThread();
    }
    this.state.isHuman = false;
    window.setInterval(this.controlUpdate.bind(this), 3 * 1e3);
  }
  componentDidUpdate(oProps) {
    if (this.bodyEl) {
      this.bodyEl.scrollTop = this.bodyEl.scrollHeight;
    }
    const userId = this.props.auth.user.id;
    const oUserId = oProps.auth.user.id;
    if (userId !== oUserId) {
      this.getThread();
    }
  }
  getThread() {
    const { props } = this;
    const { auth = {} } = props;
    const { user = {} } = auth;
    // console.log("Messaging getThread", user);
    if (user.id) {
      return props.
      getChatThread(user.id).
      then((thread) => this.lastUpdate = Date.now()).
      catch((err) => this.lastUpdate = Date.now());
    }
  }
  controlUpdate() {
    const { props } = this;
    const now = Date.now();
    const delta = props.shown ? 15 * 1e3 : 2 * 60 * 1e3;
    if (now - this.lastUpdate > delta) {
      this.getThread();
    }
  }
  render() {
    const { props } = this;
    // console.log("messaging render", props);
    const { messaging = {}, auth = {} } = props;
    const { thread = {} } = messaging;
    const { user = {} } = auth;
    return (
      <div>
        <div
          className="chat_button text-white rounded-pill py-2 px-3  bg-primaryLight"
          onClick={(e) => messaging.shown ? props.hide() : props.show()}>

          {messaging.shown ?
          <Glyphicon icon="times" /> :

          <div>
              <Glyphicon icon="comment-dots" className="mr-2" />
              {thread.unread ?
            <div
              style={{
                backgroundColor: "red",
                width: 6,
                height: 6,
                borderRadius: 3,
                position: "absolute",
                left: 30,
                top: 20
              }}>
            </div> :
            null}
              Chat
            </div>}

        </div>
        {messaging.shown ?
        user.customerId === undefined && !this.state.isHuman ?
        <div className="chat-widget">
              <div className="chat_thread card h-100 w-100">
                <div className="card-header bg-primaryLight text-white p-4">
                  <b>{props.intl.formatMessage(tchatMessages.title)}</b> <br />
                </div>
                <Capcha
              onChange={(isHuman) => {
                this.setState({ isHuman });
              }} />

              </div>
            </div> :

        <div className="chat-widget">
              <Thread
            title={
            <FormattedMessage
              id="marketing.messaging.chatTitle"
              defaultMessage="Ask your question" />}


            thread={thread}
            user={user}
            onHide={(e) => props.hide()}
            getThread={(e) => props.getChatThread(user.id)} />

            </div> :

        null}
      </div>);

  }
}

const mapStateToProps = (state) => {
  const { messaging, misc = {} } = state;
  return {
    messaging
  };
};

const mapDispatchToProps = (dispatch) => {
  return messagingActions;
};

export default injectIntl(
  withAuth(connect(mapStateToProps, mapDispatchToProps)(Messaging))
);