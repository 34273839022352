import React from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "./index";
import { withCart } from "../Cart";
import { defineMessages, injectIntl } from "react-intl";
import { Button, Link } from "Elements";
import Spinner from "components/Spinner";

import queryParser from "../utils/queryParser";

import RequestStatusAlert from "../Requests/StatusAlert";

const messages = defineMessages({
  title: { "id": "marketing.auth.UserValidateEmail/title", "defaultMessage": "Email validation" },



  pendingMessage: { "id": "marketing.auth.userValidateEmail.pendingMessage", "defaultMessage": "Validation of your email address in progress" },



  errorMessage: { "id": "marketing.auth.userValidateEmail.errorMessage", "defaultMessage": "An error occurred while validating your email address. You can start the validation procedure again by clicking on the button below." },




  expiredToken: { "id": "marketing.auth.userValidateEmail.expiredToken", "defaultMessage": "This validation link is no longer valid. Please reconnect with your login and validate your email address within 24 hours." },




  doneMessage: { "id": "marketing.auth.userValidateEmail.doneMessage", "defaultMessage": "Your email address has been validated." },



  goToAccount: { "id": "marketing.auth.userValidateEmail.goToAccount", "defaultMessage": "Access your customer account" },



  goToCart: { "id": "marketing.auth.userValidateEmail.goToCart", "defaultMessage": "View your cart" },



  tryAgain: { "id": "marketing.auth.userValidateEmail.tryAgain", "defaultMessage": "Try again" }



});

class UserValidateEmail extends React.Component {
  componentDidMount() {
    const query = queryParser(this.props.location.search);
    const token = query.token;
    if (token) {
      this.props.authActions.userValidateEmail(token);
    }
  }
  render() {
    const { props } = this;
    let request = Object.assign(
      {},
      props.auth.requests.find((r) => r.id === "auth/userValidateEmail") || {}
    );
    const query = queryParser(this.props.location.search);
    const token = query.token;
    if (!token) {
      request.status = "err";
    }
    console.log("UserValidateEmail render", props, request);
    return (
      <div className="container my-5">
        <div className="row py-5">
          <div className="col col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h4 className="m-0">
                  {props.intl.formatMessage(messages.title)}
                </h4>
              </div>
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    {request.status === "pending" ?
                    <div className="text-center text-muted p-5">
                        <div className="h1">
                          <Spinner />
                        </div>
                        <p>
                          {props.intl.formatMessage(messages.pendingMessage)}
                        </p>
                      </div> :
                    null}
                    {request.status === "done" ?
                    <div className="row">
                        <div className="col-4">
                          <img
                          src="/images/marketing/validatedEmail.svg"
                          alt=""
                          className="img-fluid"
                          // style={{ maxHeight: 100 }}
                        />
                        </div>
                        <div className="col d-flex align-items-center">
                          <div>
                            {props.intl.formatMessage(messages.doneMessage)}
                          </div>
                        </div>
                      </div> :
                    null}
                    {request.status === "err" ?
                    <div className="alert alert-danger my-3">
                        {props.intl.formatMessage(
                        messages[
                        (request.err || {}).type === "expired token" ?
                        "expiredToken" :
                        "errorMessage"]

                      )}
                      </div> :
                    null}
                    {request.status && request.status !== "pending" ?
                    <div className="d-flex mt-3 justify-content-center">
                        <Link
                        className={
                        "btn btn-" + (
                        props.cart.quotations.length ?
                        "default" :
                        "primary")}

                        onClick={(e) => props.authActions.getUser()}
                        to="/account">

                          {props.intl.formatMessage(
                          messages[
                          request.status === "err" ?
                          "tryAgain" :
                          "goToAccount"]

                        )}
                        </Link>
                        {props.cart.quotations.length &&
                      request.status !== "err" ?
                      <Link className="btn btn-primary" to="/cart">
                            {props.intl.formatMessage(messages.goToCart)}
                          </Link> :
                      null}
                      </div> :
                    null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);

  }
}

export default withCart(injectIntl(withAuth(withRouter(UserValidateEmail))));