import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link, NavLink } from "Elements";
import { HashLink } from "react-router-hash-link";
import ArrowIcon from "Icon/Arrow";
import LanguageSelector from "components/LanguageSelector";
import languages from "Intl/languages";
import config from "config";

import ressources from "marketing/productPages";

import products from "products";
//Import marketing pour eviter doublons laFactory
import marketingMessages from "../marketing/messages";

const messages = defineMessages({
  // manufacturing: {
  //   id: "marketing.manufacturing",
  //   defaultMessage: "Fabrication",
  // },
  scene: { "id": "marketing.scene", "defaultMessage": "Behind the scenes" },



  keyBenefits: { "id": "marketing.keyBenefits", "defaultMessage": "Key Benefits" },



  // products: {
  //   id: "marketing.products",
  //   defaultMessage: "Produits",
  // },
  customers: { "id": "marketing.customers", "defaultMessage": "Our clients" },



  // contactUs: {
  //   id: "marketing.contactUs",
  //   defaultMessage: "Contactez nous",
  // },
  // latestNews: {
  //   id: "marketing.latestNews",
  //   defaultMessage: "Derniers articles",
  // },
  latestTweets: { "id": "marketing.latestTweets", "defaultMessage": "Last Tweets" },



  newsletter: { "id": "marketing.newsletter", "defaultMessage": "Newsletter" },



  // contact: {
  //   id: "marketing.contact",
  //   defaultMessage: "Contact",
  // },
  chat: { "id": "marketing.chatSupport", "defaultMessage": "Chat support" },



  call: { "id": "marketing.call", "defaultMessage": "Call us" },



  // map: {
  //   id: "marketing.map",
  //   defaultMessage: "Carte",
  // },
  press: { "id": "marketing.press", "defaultMessage": "Press" },



  // blog: {
  //   id: "marketing.blog",
  //   defaultMessage: "Blog",
  // },
  CGU: { "id": "marketing.CGU", "defaultMessage": "Terms of use" },



  policy: { "id": "marketing.policy", "defaultMessage": "Cookie policy" },



  CGV: { "id": "marketing.CGV", "defaultMessage": "General sales conditions" },



  legal: { "id": "marketing.legal", "defaultMessage": "Legal Notice" },



  siteMap: { "id": "marketing.siteMap", "defaultMessage": "Sitemap" },



  cookie: { "id": "marketing.cookies", "defaultMessage": "Cookies policy" },



  politicalPrivacy: { "id": "marketing.politicalPrivacy", "defaultMessage": "Protection of personal data" }



});

const Footer = (props) => {
  // console.log('Footer render', props)
  const path = props.location.pathname.split("/");
  const dash =
  products.map((p) => p.slug).indexOf(path[2]) > -1 && props.isUserConnected;
  const root = "/" + props.match.url.split("/")[1];
  return (
    <footer id="footer" className={"footer" + (dash ? " footer-dash" : "")}>
      <svg
        className="footer__top"
        viewBox="0 0 100 10"
        preserveAspectRatio="none">

        <path d="M0 0L0 10L100 10L100 0Q50 20, 0 0z" />
      </svg>
      <HashLink to="#main-navigation">
        <ArrowIcon className="go_to_top" />
      </HashLink>
      <div className="inner sep-bottom-md pt-5 pb-4 text-center text-sm-left">
        <div className={"container"}>
          <div className="row">
            <div className="col-12 text-center sep-bottom-sm">
              <img
                alt=""
                width="230"
                height="117"
                src="/images/logo/logo.svg"
                className="logo img-fluid" />

              <hr />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3">
              <h4>
                <Link to={root}>EMSFACTORY</Link>
              </h4>
              <ul className="list-unstyled">
                <li>
                  <HashLink
                    to={root + "#manufacturing"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(marketingMessages.Fabrication)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#behind_the_scene"}
                    scroll={(el) =>
                    el.scrollIntoView({ behavior: "instant", block: "start" })}>


                    {props.intl.formatMessage(messages.scene)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#keyBenefits"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(messages.keyBenefits)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#products"}
                    scroll={(el) =>
                    el.scrollIntoView({ behavior: "instant", block: "start" })}>


                    {props.intl.formatMessage(marketingMessages.products)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#customers"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(messages.customers)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#contactUs"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(marketingMessages.contactUs)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#latestNews"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(marketingMessages.lastArticle)}
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={root + "#latestNews"}
                    scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "instant",
                      block: "center"
                    })}>


                    {props.intl.formatMessage(messages.latestTweets)}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3">
              <h4>
                <Link to={root + "#products"}>
                  {props.intl.formatMessage(marketingMessages.products)}
                </Link>
              </h4>
              <ul className="list-unstyled">
                {ressources.
                filter((c) => c.slug != "emslabs").
                map((r, i) => {
                  return (
                    <li key={i}>
                        <Link to={root + "/" + r.slug}>{r.title}</Link>
                      </li>);

                })}
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3">
              <h4>
                <Link to={root + "/ressources"}>
                  {props.intl.formatMessage(marketingMessages.ressources)}
                </Link>
              </h4>
              <ul className="list-unstyled">
                {ressources.
                filter((c) => c.slug != "emslabs").
                map((r, i) => {
                  return (
                    <li key={i}>
                        <Link to={root + "/ressources/" + r.slug}>
                          {r.title}
                        </Link>
                      </li>);

                })}
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3">
              <h4 className="mb-3">
                <Link to={root + "/factory"}>
                  {props.intl.formatMessage(marketingMessages.factory)}
                </Link>
              </h4>

              <h4 className="mb-3">
                <Link to={root + "/pricing"}>
                  {props.intl.formatMessage(marketingMessages.prices)}
                </Link>
              </h4>
              <hr />
              <h4 className="my-3">
                <Link to="/contact">
                  {props.intl.formatMessage(marketingMessages.contact)}
                </Link>
              </h4>
              <h4 className="mb-3">
                <Link to="/faq">
                  {props.intl.formatMessage(marketingMessages.faq)}
                </Link>
              </h4>
              <h4 className="mb-3">
                <Link to="/press">
                  {props.intl.formatMessage(messages.press)}
                </Link>
              </h4>
              <h4 className="mb-3">
                <Link to="/blog">
                  {props.intl.formatMessage(marketingMessages.blog)}
                </Link>
              </h4>
              <img
                width="255"
                height="72"
                data-src="/images/marketing/paymentModes.jpg"
                className="footer_img img-fluid lazyload" />

            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 text-center mt-3 py-3 px-5 d-flex justify-content-around flex-wrap border-top">
              {Object.keys(languages).map((l) =>
              <NavLink key={l} lang={l} to="/" className="mx-2">
                  {languages[l]}
                </NavLink>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 offset-4 text-center mb-4">
              <hr />
              <p className="mt-4">
                <b className="text-primary">©2021 EMSFACTORY</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SubFooter />
    </footer>);

};

const mapStateToProps = (state) => {
  return {
    isUserConnected:
    state.auth.user.webLogin && state.auth.user.email ? true : false,
    cpmVersion: state.misc.cpmVersion
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Footer)));

export const SubFooter = injectIntl(
  connect(mapStateToProps)((props) => {
    console.log("SubFooter render", props);
    return (
      <div
        className={
        "footer_legals" + (props.account ? " footer_legals-account" : "")}>


        <div className="d-flex justify-content-between align-items-center">
          <div className="mx-2 footer_legals__versions">
            <small>v{config.version}</small>
          </div>
          <div className="d-flex justify-content-around flex-wrap">
            <Link className="text-white mr-3 mt-1" to="/cgu">
              {props.intl.formatMessage(messages.CGU)}
            </Link>
            <Link className="text-white mx-3 mt-1" to="/cgv">
              {props.intl.formatMessage(messages.CGV)}
            </Link>
            <Link className="text-white mx-3 mt-1" to="/cookies">
              {props.intl.formatMessage(messages.cookie)}
            </Link>
            <Link className="text-white mx-3 mt-1" to="/legal_notice">
              {props.intl.formatMessage(messages.legal)}
            </Link>
            {/* <Link className="text-white" to="/sitemap">
              {props.intl.formatMessage(messages.siteMap)}
              </Link> */}
            <Link className="text-white mx-3 mt-1" to="/privacy_policy">
              {props.intl.formatMessage(messages.politicalPrivacy)}
            </Link>
            <div className="ml-3 mt-1">
              <LanguageSelector dropup />
            </div>
          </div>
          <div style={{ width: 100 }}></div>
        </div>
        {/* <div className="container">
          <div className="row">
          <div className="col-xl-10 offset-xl-1 d-flex justify-content-around flex-wrap">
           <Link className="text-white mr-2 mt-1" to="/cgu">
             {props.intl.formatMessage(messages.CGU)}
           </Link>
           <Link className="text-white mx-2 mt-1" to="/cgv">
             {props.intl.formatMessage(messages.CGV)}
           </Link>
           <Link className="text-white mx-2 mt-1" to="/cookies">
             {props.intl.formatMessage(messages.cookie)}
           </Link>
           <Link className="text-white mx-2 mt-1" to="/legal_notice">
             {props.intl.formatMessage(messages.legal)}
           </Link>
           <Link className="text-white ml-2 mt-1" to="/privacy_policy">
             {props.intl.formatMessage(messages.politicalPrivacy)}
           </Link>
           <LanguageSelector dropup />
          </div>
          </div>
          </div> */}
      </div>);

  })
);