import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter, matchPath } from "react-router-dom";
import { Glyphicon, Button, Link } from "Elements";
import helpActions from "./actions";
import messagingActions from "Messaging/actions";
import Spinner from "components/Spinner";
import Content from "components/ContentEditor/Content";
import cmsActions from "Cms/actions";
import routes from "./routes.json";
import marketingMessages from "marketing/messages";
const mapDispatchToProps = (dispatch) => {
  return helpActions;
};

class Help extends React.Component {
  // // code seb

  // //function cherchant le fichier via un id fournis
  // const findHelpMessage = (id, lang) => {
  //   let mesHelpMessages = { ...cmsActions.getHelpMessage(id, lang) };
  //   return mesHelpMessages;
  // };
  // console.log("salut", cmsActions.getHelpMessage("pcbfiles", "fr"));
  // console.log("encode uri", encodeURIComponent("pcbfiles"));
  // //Je mets les domains ici pour  charger les messages par domains
  // const domains = [
  //   "marketing",
  //   "account",
  //   "pcba",
  //   "wiring",
  //   "pcb",
  //   "enclosure",
  //   "coating",
  //   "xray",
  //   "assembly",
  //   "rework",
  //   "engineering",
  //   // "emslabs"
  // ];
  // //Je met les steps  pour charger les messages grâce à une combinaisons domains.step
  // const stepsAll = [
  //   "bom",
  //   "infos",
  //   "pcb",
  //   "files",
  //   "coating",
  //   "xray",
  //   "options",
  //   "expedition",
  // ];
  // let monAdresseSplit = window.location.href.split("/");
  // //Creation de l'id qui me permettra de  attraper le message avec la combinaisons domains + steps
  // let idMessageHelp = "";

  // for (let partieAdrr of monAdresseSplit) {
  //   // console.log("le tableaux que j'ai ", monAdresseSplit);
  //   // console.log(
  //   //   "verif de partieAddr",
  //   //   domains.includes(partieAdrr) + " la partie " + partieAdrr
  //   // );
  //   //Verif si il y'a le domaine dans le lien
  //   if (domains.includes(partieAdrr)) {
  //     idMessageHelp = partieAdrr;
  //     //Maintenant que j'ai le domains  je cherche le step  si il y'en as pas c'est que je suis sur la page d'acceuil du domains
  //     for (let partieAdrrStep of monAdresseSplit) {
  //       if (
  //         stepsAll.includes(partieAdrrStep) &&
  //         !idMessageHelp.includes(partieAdrrStep)
  //       ) {
  //         idMessageHelp += partieAdrrStep;
  //       }
  //     }
  //   }
  // }
  // // console.log("findHelpMessage", typeof findHelpMessage(idMessageHelp, "fr"));
  // console.log("id helpMessage ", idMessageHelp);

  // let informationMessage;
  // //Recupération de ma valeur dans le promise (Toutes les informations de helpMessage recupérer dans le json)
  // cmsActions.getHelpMessage("pcbfiles", "fr").then((value) => {
  //   console.log("La valeur", value);
  // });
  // console.log("les infos mess", informationMessage);

  //****************************************************************** */
  state = {};
  componentDidUpdate(oProps) {
    const { props } = this;
    if (props.shown && props.location.pathname !== oProps.location.pathname) {
      props.hide();
    }
    if (props.shown && !oProps.shown) {
      const helpMessageId = props.helpMessageId;
      if (helpMessageId) {
        this.setState({
          loadingHelpMessage: true,
          helpMessageId: helpMessageId
        });
        helpActions.
        getMessage(helpMessageId).
        then((message) => this.setState({ loadingHelpMessage: false })).
        catch((e) =>
        this.setState({ loadingHelpMessage: false, helpMessageId: false })
        );
      } else {
        this.setState({ helpMessageId: false });
      }
    }
  }

  render() {
    const { props, state } = this;
    //NOTE ici  je définis les valeur de l'objet defaultHelpMessage afin que les valeurs sois traduite

    const defaultHelpMessage = {
      title: props.intl.formatMessage(marketingMessages.help),
      content: props.intl.formatMessage(marketingMessages.doYouNeedHelp),
      cols: [
      {
        title: props.intl.formatMessage(marketingMessages.faq),
        content: [
        {
          type: "P",
          content: props.intl.formatMessage(
            marketingMessages.questionRelatetoOurFunctionning
          )
        }],

        action: {
          to: "/faq",
          text: props.intl.formatMessage(marketingMessages.checkFaq)
        }
      },
      {
        title: props.intl.formatMessage(marketingMessages.ressources),
        content: [
        {
          type: "P",
          content: props.intl.formatMessage(
            marketingMessages.ifAskIsMoreTechnical
          )
        }],

        action: {
          to: "/ressources",
          text: props.intl.formatMessage(marketingMessages.ressources)
        }
      },
      {
        title: "Chat",
        content: [
        {
          type: "P",
          content: props.intl.formatMessage(
            marketingMessages.dontFindAnswer
          )
        }],

        action: {
          type: "messagingShowAction"
        }
      }]

    };

    const { helpMessage = defaultHelpMessage } = props;

    if (!props.shown) {
      return null;
    }
    console.log("le message", typeof helpMessage.messages);
    return (
      <div
        style={{
          background: "#000c",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1100
        }}
        onClick={(e) => props.hide()}>

        <div
          style={{
            position: "fixed",
            top: 15,
            right: 15,
            color: "#fff",
            cursor: "pointer"
          }}
          onClick={(e) => props.hide()}>

          <Glyphicon glyph="times" />
        </div>
        <div className="container text-white text-center d-flex flex-column justify-content-center h-100">
          {state.loadingHelpMessage ?
          <Spinner /> :

          //Ici que j'affiche le message recupérer
          <HelpMessage
            close={(e) => props.hide()}
            //verification si il y'a un message spécifique à afficher grâce à l'id sinon affiche le message de base
            // {...(typeof state.cms.helpMessages !== undefined
            //   ? state.cms.helpMessages[0].messages
            //   : defaultHelpMessage)}

            {...helpMessage.hasOwnProperty("messages") ?
            helpMessage.messages :
            defaultHelpMessage} />}


        </div>
      </div>);

  }
}

const mapStateToProps = (state, props) => {
  const { cms = {} } = state;
  const { helpMessages = [] } = cms;
  //variable pour detecter un id  de typ uuid v4
  const UUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/;
  const getHelpMessageId = (path = props.location.pathname) => {
    let lienSplit = path.split("/");
    let monId = "";

    //NOTE soucis avec ce code quand les id ne corresponde pas à des uuid v4 et que les id sont length<30 (03/03/21 ne fonctionne pas dans rework)
    //Message ne sont pas dans la branche marketing
    if (lienSplit.length > 3) {
      //Je verifie si il y'a und id ou pas le ou >30 comme securité si ça match pas (parfois le cas)
      if (
      lienSplit[lienSplit.length - 1].match(UUID) ||
      lienSplit[lienSplit.length - 1].length > 30)
      {
        monId = lienSplit[lienSplit.length - 2] + "Id";
      } else if (
      //Je met le ou >30 car parfois il ne match pas c'est une sorte de sécurité
      lienSplit[lienSplit.length - 2].match(UUID) ||
      lienSplit[lienSplit.length - 2].length > 30 &&
      !lienSplit[lienSplit.length - 1].match(UUID) ||
      lienSplit[lienSplit.length - 1].length > 30)
      {
        //Dans le cas ou je suis dans un step d'un module
        monId = lienSplit[2] + lienSplit[lienSplit.length - 1];
      } else {
        monId = lienSplit[2] + lienSplit[3];
      }
    } else {
      //verification que c'est pas la page d'acceuil et que ça soit pas une langue le dernier split
      if (lienSplit[lienSplit.length - 1].length > 2) {
        monId = lienSplit[lienSplit.length - 1];
      } else {
        monId = "home";
      }
    }
    //Conditions speciale pour account/profile dans "Mon profil" pour avoir un id différent pour chaque steps
    if (
    monId === "accountprofile" &&
    lienSplit[lienSplit.length - 1] !== "profile")
    {
      monId = monId + lienSplit[lienSplit.length - 1];
    }
    return monId;
  };
  const helpMessageId = getHelpMessageId();
  const helpMessage = helpMessages.find((m) => m.id === helpMessageId);
  return { helpMessageId, shown: state.help.shown, helpMessage };
  //Dois retrouver le message via l'id actuelle et cherche le fichier correspendant sinon retourne le message par default
};

// getHelpMessageId();
//FIXME const helpMessageId = getHelpMessageId();
// const helpMessage = helpMessages.find((m) => m.id === helpMessageId);
// return {
//   getHelpMessageId,
//   helpMessage,
//   ...state.help,
// };

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Help))
);

const HelpMessage = injectIntl((props) =>
<div>
    {props.title ? <h1>{props.title}</h1> : null}
    <div className="row text-center">
      <div className="col-6 offset-3">
        {props.content ? <Content blocks={props.content} /> : null}
        {props.video ?
      <div className="my-3">
            <iframe
          title="youtube video"
          width="100%"
          height="315"
          src={"https://www.youtube.com/embed/" + props.video + "?rel=0"}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
          </div> :
      null}
      </div>
      {props.cols ?
    <div className="col-12">
          <div className="row m-3">
            {props.cols.map((col, i) =>
        <div className="col">
                {col.title ? <strong>{col.title}</strong> : null}
                {col.content ? <Content blocks={col.content} /> : null}
                {col.action ?
          col.action.type === "messagingShowAction" ?
          <a
            href="#"
            className="btn btn-outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              messagingActions.show();
            }}>

                      <FormattedMessage
              id="marketing.help.chatWithTechn"
              defaultMessage="Talk to a technician" />

                    </a> :

          <Link
            className="btn btn-outline-secondary"
            to={col.action.to}>

                      {col.action.text}
                    </Link> :

          null}
              </div>
        )}
          </div>
        </div> :
    null}

      <div className="text-center col-12 mt-5">
        <Button onClick={(e) => props.close()}>
          {props.intl.formatMessage(marketingMessages.close)}
        </Button>
      </div>
    </div>
  </div>
);

const defaultHelpMessage = {
  title: "Aide",
  content:
  "Vous avez besoin d'aide ? Nous vous offrons plusieurs possibilités pour trouver l'aide dont vous avez besoin.",
  cols: [
  {
    title: "FAQ",
    content: [
    {
      type: "P",
      content:
      "Si votre question porte sur notre fonctionnement en général, vous pouvez consulter notre FAQ"
    }],

    action: {
      to: "/faq",
      text: "Consulter la FAQ"
    }
  },
  {
    title: "Ressources",
    content: [
    {
      type: "P",
      content:
      "Si votre question est plus techniques, vous trouverez surement votre réponse dans les articles de Design aids"
    }],

    action: {
      to: "/ressources",
      text: "Ressources"
    }
  },
  {
    title: "Chat",
    content: [
    {
      type: "P",
      content:
      "Si vous ne trouvez pas de réponse, vous pouvez poser votre question à un de nos techniciens"
    }],

    action: {
      type: "messagingShowAction"
    }
  }]

};