import React from "react";
import { FormattedMessage } from "react-intl";
import ReactDom from "react-dom";
import { Link } from "Elements";

const sizes = {
  pcba: {
    width: 72,
    height: 70
  },
  wiring: {
    width: 42,
    height: 70
  },
  pcb: {
    width: 94,
    height: 70
  },
  enclosure: {
    width: 67,
    height: 70
  },
  coating: {
    width: 43,
    height: 70
  },
  xray: {
    width: 85,
    height: 70
  },
  assembly: {
    width: 68,
    height: 70
  },
  rework: {
    width: 97,
    height: 70
  },
  engineering: {
    width: 68,
    height: 70
  }
};

class SquareButton extends React.Component {
  state = { el: false };
  componentDidMount() {
    // const { props, state } = this;
    const { props } = this;
    // const product = props.product || {};
    // if (['engineering', 'emslabs'].indexOf(product.slug) >= 0) {
    //   return
    // }
    const el = ReactDom.findDOMNode(this.el);
    const box = el.getBoundingClientRect();
    // console.log('box', box)
    this.setState({
      height: box.width
    });
  }
  render() {
    // const { props, state } = this;
    const { props } = this;
    const { product = {} } = props;
    const { IntlComponent = (props) => props.children(product) } = product;
    console.log("SquareButton render", props);
    return (
      <IntlComponent>
        {(product) => {
          const size = sizes[product.slug] || {};
          return (
            <div
              className={
              "square_product_link square_product_link-" +
              product.slug + (
              props.masonry ? " square_product_link-masonry" : "")}>


              <Link
                to={
                "/" +
                product.slug + (
                props.soon ? "" : props.productLink || "")}

                // className="d-flex flex-column justify-content-around"
                ref={(el) => this.el = el}
                style={{
                  height: "100%"
                }}
                onClick={(e) => props.onClick ? props.onClick() : e}>

                <img
                  {...size}
                  className="lazyload"
                  data-src={"/images/products/" + product.slug + ".svg"}
                  alt="" />

                <span>{product.title}</span>
                {props.soon ?
                <small
                  className="badge badge-pill badge-light pt-2 px-lg-3 px-1"
                  style={{
                    fontFamily: "IntroCond",
                    textTransform: "none",
                    fontSize: 12
                  }}>

                    <FormattedMessage
                    id="marketing.soonAvailable"
                    defaultMessage="Coming soon" />

                  </small> :
                null}
              </Link>
            </div>);

        }}
      </IntlComponent>);

  }
}

export default SquareButton;