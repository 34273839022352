import React from "react";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl } from
"react-intl";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  SimpleModal,
  Button,
  HelpBlock } from
"Elements";
import Spinner from "components/Spinner";

import { withAuth } from "./Context";

// import RequestStatusAlert from "../Requests/StatusAlert";
import requestsActions from "../Requests/actions";

import marketingMessages from "marketing/messages";
const messages = defineMessages({
  emailPlaceholder: { "id": "marketing.auth.emailPlaceholder", "defaultMessage": "The email address associated with your account" }



});

class UserLoginRequest extends React.Component {
  state = {
    forgottenEmail: ""
  };
  componentDidUpdate(oProps) {
    if (!this.props.show && oProps.show) {
      requestsActions.remove("auth/userLoginRequest");
    }
  }
  render() {
    const { props, state } = this;
    const userLoginRequest =
    props.auth.requests.find((r) => r.id === "auth/userLoginRequest") || {};
    const requestErr = userLoginRequest.err || {};
    const invalidLoginErr =
    userLoginRequest.status === "err" && requestErr.type === "invalid value";
    return (
      <SimpleModal
        header={
        <FormattedMessage
          id="marketing.auth.forgottenLoginTitle"
          defaultMessage="Forgotten login" />}


        footer={
        <div>
            <Button type="button" onClick={props.onHide}>
              <FormattedMessage {...marketingMessages.close} />
            </Button>
            {!state.request || state.request === "err" ?
          <Button
            className="ml-1"
            bsStyle="primary"
            disabled={userLoginRequest.status === "pending"}>

                {userLoginRequest.status === "pending" ? <Spinner /> : null}
                &nbsp;
                <FormattedMessage {...marketingMessages.send} />
              </Button> :
          null}
          </div>}

        onSubmit={(e) => {
          e.preventDefault();
          this.setState({ request: "pending" });
          props.authActions.
          userLoginRequest(state.forgottenEmail).
          then((e) => this.setState({ request: "done" })).
          catch((err) => {
            console.log("UserLoginRequest onSubmit catch", err);
            this.setState({ request: "err" });
          });
        }}
        onHide={(e) => props.onHide(e)}>

        {!state.request || state.request === "err" ?
        <div>
            <div className="alert alert-info">
              <FormattedMessage
              id="marketing.auth.forgottenLoginInfo"
              defaultMessage="You can retrieve your login at any time, thanks to your email entered in your account, in case of problem contact us via the chat or the contact form." />

            </div>
            <FormGroup validationState={invalidLoginErr ? "error" : null}>
              <ControlLabel>
                {props.intl.formatMessage(marketingMessages.emailLabel)}
              </ControlLabel>
              <FormControl
              onChange={(e) => {
                this.setState({
                  forgottenEmail: e.target.value
                });
              }}
              value={state.forgottenEmail}
              placeholder={props.intl.formatMessage(
                messages.emailPlaceholder
              )} />

              {invalidLoginErr ?
            <HelpBlock>
                  <FormattedMessage
                id="marketing.auth.invalidEmailAddress"
                defaultMessage="Invalid email address" />

                </HelpBlock> :
            null}
            </FormGroup>
            {state.request === "err" ?
          <div className="alert alert-danger mt-3">
                {props.intl.formatMessage(marketingMessages.webLoginInvalidErr)}
              </div> :
          null}
          </div> :
        state.request === "done" ?
        <div className="alert alert-success">
            <FormattedHTMLMessage
            id="marketing.auth.UserLoginRequest.doneMessage"
            defaultMessage="<b>An email has been sent to you.</b> Please follow the instructions in this email. <br/> If you do not receive this email or have any problems, please contact us via the chat or the contact form." />


          </div> :

        <div className="p-5 text-center">
            <Spinner />
          </div>}

      </SimpleModal>);

  }
}

export default injectIntl(withAuth(UserLoginRequest));