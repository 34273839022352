import React from "react";
import { FormattedMessage } from "react-intl";
import { SimpleModal } from "Elements";

const fixedStyles = {
  position: "absolute",
  zIndex: 99,
  background: "rgba(0,0,0,0.5)",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  paddingTop: "20%"
};

const ErrorMessage = (props) =>
props.modal ?
<SimpleModal
  noHeader
  footer={<div className="text-center w-100">{props.children}</div>}>

      <div className="text-center">{props.message}</div>
    </SimpleModal> :

<div className="row error_message" style={props.fixed ? fixedStyles : null}>
      <div className="col-md-6 offset-md-3">
        <div className="card error lead sep-top-lg sep-bottom-lg text-center">
          <div className="card-header">
            <b>
              {props.message ||
          <FormattedMessage
            id="marketing.defaultErrorMessage"
            defaultMessage="An error has occurred" />}


            </b>
          </div>
          <div className="card-content text">{props.children}</div>
        </div>
      </div>
    </div>;


export default ErrorMessage;