import React from "react";
import Spinner from "components/Spinner";
import Button from "./Button";
import { Glyphicon } from ".";

class RequestButton extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    let nProps = Object.assign({}, props);
    delete nProps.onClick;
    return (
      <Button
        {...props}
        bsStyle={
        state.request === "error" ?
        "danger" :
        state.request === "done" ?
        "success" :
        props.bsStyle}

        outline={state.request === "pending" || props.outline}
        onClick={(e) => {
          this.setState({ request: "pending" });
          props.
          onClick(e).
          then((e) => {
            this.setState({ request: "done" });
            return e;
          }).
          catch((err) => {
            this.setState({ request: "error" });
            return err;
          });
        }}>

        {state.request === "pending" ?
        <Spinner /> :
        state.request || props.icon ?
        <Glyphicon
          icon={
          state.request === "error" ?
          "exclamation-triangle" :
          state.request === "done" && !props.noSuccessIcon ?
          "check" :
          props.icon} /> :


        null}{" "}
        {props.children}
      </Button>);

  }
}

export default RequestButton;