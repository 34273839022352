import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Section from "marketing/components/Section";
import { Link } from "Elements";
import messagingActions from "Messaging/actions";

import marketingMessages from "../messages";

const Page = (props) =>
<div className="helpTile">
    <Section
    className="helpTile pt-5"
    title={
    <FormattedMessage
      id="marketing.needHelp"
      defaultMessage="Need help ?" />}>



      <div className="pt-xl-5">
        <div className="row my-3">
          <div className="col-sm my-3 d-flex align-items-center flex-column helpTile_img">
            <Link to="/faq">
              <img
              width="145"
              height="145"
              className="lazyload"
              data-src="/images/marketing/contact/faq.svg"
              alt="" />

            </Link>
            <h6 className="my-3">
              {props.intl.formatMessage(marketingMessages.faq)}
            </h6>
          </div>
          <div className="col-sm my-3 d-flex align-items-center flex-column">
            <Link to="/ressources">
              <img
              width="145"
              height="145"
              className="lazyload"
              data-src="/images/marketing/contact/ressources.svg"
              alt="" />

            </Link>
            <h6 className="my-3">
              {props.intl.formatMessage(marketingMessages.ressources)}
            </h6>
          </div>
          <div className="col-sm my-3 d-flex align-items-center flex-column">
            <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              messagingActions.show();
            }}>

              <img
              width="145"
              height="145"
              className="lazyload"
              data-src="/images/marketing/contact/chat.svg"
              alt="" />

            </a>
            <h6 className="my-3">
              {props.intl.formatMessage(marketingMessages.chat)}
            </h6>
          </div>
        </div>
      </div>
    </Section>
  </div>;


export default injectIntl(Page);