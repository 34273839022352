import React from "react";
import { injectIntl, defineMessages } from "react-intl";

import { NavHashLink } from "Elements";
import Section from "../components/Section";

import messagingActions from "Messaging/actions";
import marketingMessages from "../messages";
const messages = defineMessages({
  // title: {
  //   id: "marketing.contactUs.title",
  //   defaultMessage: "Contactez nous",
  // },
  // faq: {
  //   id: "marketing.contactUs.faq",
  //   defaultMessage: "FAQ",
  // },
  chat: { "id": "marketing.contactUs.chat", "defaultMessage": "Chat support" },



  visitUs: { "id": "marketing.contactUs.visitUs", "defaultMessage": "Come and see us" }



});

const ContactUs = (props) =>
<div className="contact_us">
    <Section
    title={props.intl.formatMessage(marketingMessages.contactUs)}
    className="contact_us_section">

      <div className="row text-center justify-content-around" id="contactUs">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-4 contact_us__button">
          <NavHashLink to={"/faq"}>
            <img
            width="145"
            height="145"
            className="lazyload"
            data-src="/images/marketing/contact/faq.svg"
            alt="" />

            <p className="text-secondary-pcba">
              {props.intl.formatMessage(marketingMessages.faq)}
            </p>
          </NavHashLink>
        </div>
        <div
        className="col-12 col-sm-6 col-md-4 col-lg-3 my-4 contact_us__button pointer"
        onClick={(e) => messagingActions.show()}>

          <img
          width="145"
          height="145"
          className="lazyload"
          data-src="/images/marketing/contact/chat.svg"
          alt="" />

          <p className="text-secondary-pcba">
            {props.intl.formatMessage(messages.chat)}
          </p>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-4 contact_us__button">
          <NavHashLink to="/ressources">
            <img
            width="145"
            height="145"
            className="lazyload"
            data-src="/images/marketing/contact/ressources.svg"
            alt="" />

            <p className="text-secondary-pcba">
              {props.intl.formatMessage(marketingMessages.ressources)}
            </p>
          </NavHashLink>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-4 contact_us__button">
          <NavHashLink
          to="/contact#map"
          scroll={(el) =>
          el.scrollIntoView({ behavior: "instant", block: "center" })}>


            <img
            width="145"
            height="145"
            className="lazyload"
            data-src="/images/marketing/contact/visit.svg"
            alt="" />

            <p className="text-secondary-pcba">
              {props.intl.formatMessage(messages.visitUs)}
            </p>
          </NavHashLink>
        </div>
      </div>
    </Section>
  </div>;


export default injectIntl(ContactUs);