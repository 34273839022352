import React from "react";
import Modal from "./Modal";
import SimpleModal from "./SimpleModal";
import Dropdown from "./Dropdown";
import Link from "./Link";
import NavLink from "./NavLink";

import { NavHashLink } from "react-router-hash-link";
import NavDropdown from "./NavDropdown";

import ScrollTable from "./ScrollTable";

// Badge
import { Badge } from "reactstrap";

// Buttons
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import DropdownButton from "./DropdownButton";
import MenuItem from "./MenuItem";
import RequestButton from "./RequestButton";
import ConfirmButton from "./ConfirmButton";

import ColorPicker from "./ColorPicker";

import { Collapse, NavbarToggler } from "reactstrap";

// Form
import Checkbox from "./Checkbox";
import ControlLabel from "./ControlLabel";
import FormControl from "./FormControl";
import FloatInput from "./FloatInput";
import SIInput from "./SIInput";
import NumberInput from "./NumberInput";
import DateInput from "./DateInput";
import DateTimeInput from "./DateTimeInput";
import FormGroup from "./FormGroup";
import HelpBlock from "./HelpBlock";
import InputGroup from "./InputGroup";
import Form from "./Form";
import Radio from "./Radio";

// Icons
import FontIcon from "components/FontIcon";
import Image from "./Image";

// Navs
import Nav from "./Nav";
import NavItem from "./NavItem";
import PassiveNavLink from "./PassiveNavLink";
import Tabs from "./Tabs";

// Overlays
import OverlayTrigger from "./OverlayTrigger";
import Tooltip from "./Tooltip";
import Popover from "./Popover";

// Carousel
import Carousel from "./Carousel";
import Gallery from "./Gallery";

import Panel from "./Panel";

export { Badge };
export { Button };
export { RequestButton };
export { ConfirmButton };
export { ButtonGroup };
export { Carousel };
export { Gallery };
// export const CarouselItem = reactstrap.CarouselItem
// export const CarouselControl = reactstrap.CarouselControl
// export const CarouselIndicators = reactstrap.CarouselIndicators
// export const CarouselCaption = reactstrap.CarouselCaption
// export const UncontrolledCarousel = reactstrap.UncontrolledCarousel
export { Checkbox };
export { Collapse };
export { NavbarToggler };
export { ColorPicker };
export { ControlLabel };
export { DropdownButton };
export { Dropdown };
export { Form };
export { FormControl };
export { FloatInput };
export { SIInput };
export { NumberInput };
export { FormGroup };
export { DateInput };
export { DateTimeInput };
export const Glyphicon = (props) => <FontIcon {...props} />;
export { Image };
export { HelpBlock };
export { InputGroup };
export { Link };
export { MenuItem };
export { Modal };
export { SimpleModal };
export { Nav };
export { NavDropdown };
export { NavItem };
export { NavLink };
export { NavHashLink };
export { OverlayTrigger };
export { Panel };
export const PanelGroup = (props) => <div {...props}>{props.children}</div>;
export { PassiveNavLink };
export { Tabs };
export { Popover };
export const ProgressBar = (props) =>
<div className="progress">
    <div
    className={
    "progress-bar progress-bar-striped" + (
    props.animated ? " progress-bar-animated" : "")}

    style={{ width: props.now + "%" }}>
  </div>
  </div>;

export { Radio };
export { ScrollTable };
export { Tooltip };