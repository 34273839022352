import React from "react";
import { injectIntl } from "react-intl";

import Content from "components/ContentEditor/Content";
import { NavHashLink } from "react-router-hash-link";
import Section from "marketing/components/Section";

class Map extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    return (
      <Section
        noContainer
        className="map lazyload"
        data-bg="/images/marketing/factory/Map/map.svg"
        // bgImage="http://www.smt-china.com/images/oktek_okano_smt_pcba_machine1.jpg"
      >
        <svg
          className="footer__top"
          viewBox="0 0 100 10"
          preserveAspectRatio="none">

          <path d="M0 0L0 10L100 10L100 0Q50 20, 0 0z" />
        </svg>
        {props.photos ?
        <div className="col-lg-3">
            <div className="map_gallery mt-3 ">
              <img src={props.photos[0].src} alt="" />
              <h4></h4>
            </div>
            <div className="row">
              {props.photos.slice(1, 5).map((image, i) =>
            <div
              key={i}
              className={
              "col " + (i === 0 ? "pr-1" : i === 3 ? "pl-1" : "px-1")}>


                  <img
                src={image.src}
                className="img-fluid"
                alt={image.alt}
                onClick={(e) => props.showModal(props.photos, i)}
                style={{ cursor: "pointer" }} />

                </div>
            )}
            </div>
          </div> :
        null}
        {props.text ?
        <div className="col-lg map__text">
            <p>{props.text}</p>
          </div> :
        null}
        {props.contactezNous ?
        <div className="map_contactezNous d-flex flex-column justify-content-between">
            <h4>{props.findUs}</h4>
            <div className="d-flex flex-column align-items-end">
              <Content blocks={props.address} />
            </div>
            <strong className="pt-4">{props.openHours}</strong>
          </div> :
        null}
        {props.contact ?
        <div className="map_contact">
            <NavHashLink
            to="/contact#map"
            className="btn btn-outline-primary map_btn"
            scroll={(el) =>
            el.scrollIntoView({ behavior: "instant", block: "center" })}>


              {props.contact}
            </NavHashLink>
          </div> :
        null}
      </Section>);

  }
}

export default injectIntl(Map);