import React from "react";
import { Glyphicon, Popover } from "Elements";

class ShareOnSN extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { url } = props;

    return (
      <div className="d-flex justify-content-center">
        <div className="mx-2">
          <a
            target="_blank"
            href={"https://twitter.com/intent/tweet?text=" + url}
            className="share_btn d-flex justify-content-center align-items-center my-2">

            <Glyphicon glyph="twitter" lib="fab" />
          </a>
        </div>
        <div className="mx-2">
          <a
            target="_blank"
            href={"https://www.facebook.com/sharer/sharer.php?u=" + url}
            className="share_btn d-flex justify-content-center align-items-center my-2">

            <Glyphicon glyph="facebook-f" lib="fab" />
          </a>
        </div>
        <div className="mx-2">
          <a
            target="_blank"
            href={"https://www.linkedin.com/sharing/share-offsite/?url=" + url}
            className="share_btn d-flex justify-content-center align-items-center my-2">

            <Glyphicon glyph="linkedin-in" lib="fab" />
          </a>
        </div>
        <div className="mx-2">
          <Popover show={state.popoverOpen} body={url}>
            <div
              className="share_btn d-flex justify-content-center align-items-center pt-1 my-2 pointer"
              style={{ fontSize: "16px" }}
              onClick={(e) =>
              this.setState({ popoverOpen: !state.popoverOpen })}>


              <strong>URL</strong>
            </div>
          </Popover>
        </div>
      </div>);

  }
}

export default ShareOnSN;