import React from "react";
import tiles from "marketing/tiles";
import products from "products";
const soonProducts = products.filter((p) => p.soon).map((p) => p.slug);

class StructuredPage extends React.Component {
  render() {
    const { props } = this;
    const { data = {}, style } = props;
    const { content = [], className = "" } = data;
    const nContent =
    soonProducts.indexOf(data.id) < 0 ?
    content :
    [content[0], ...content.slice(-1)];
    console.log("StructuredPage render", data, content);
    return (
      <div
        className={
        "lazyload marketing marketing_page-" + data.id + " " + className}

        data-bg={
        data.id === "home" ?
        "/images/marketing/embleme.jpg" :
        products.map((p) => p.slug).indexOf(data.id) >= 0 ?
        "/images/marketing/embleme-" + data.id + ".jpg" :
        null}

        style={style || { paddingTop: 134, paddingBottom: 120 }}>

        {(Array.isArray(nContent) ? nContent : []).map((tile, i) => {
          const tileElement = tiles[tile.type];
          console.log("StructuredPage render tile", tile);
          if (!tileElement) {
            return null;
          }
          return React.createElement(tiles[tile.type], {
            page: data,
            data: tile.data,
            key: i
          });
        })}
      </div>);

  }
}

export default StructuredPage;