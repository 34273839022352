import React from "react";
import Modal from "./Modal";

const SimpleModal = (props) => {
  return (
    <Modal
      show
      onHide={props.onHide}
      bsSize={props.bsSize}
      dialogClassName={
      props.className + (
      props.footer ? " modal-dialog-footer" : "") + (
      props.header ? " modal-dialog-header" : "")}>


      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return typeof props.onSubmit === "function" ?
          props.onSubmit(e) :
          false;
        }}>

        {props.noHeader ? null :
        <Modal.Header toggle={props.onHide}>{props.header}</Modal.Header>}

        <Modal.Body>{props.children}</Modal.Body>
        {props.footer ? <Modal.Footer>{props.footer}</Modal.Footer> : null}
      </form>
    </Modal>);

};

export default SimpleModal;