import React, { Component } from "react";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { withRouter, Switch, Route } from "react-router";
import { Button, Glyphicon } from "Elements";

import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import Login from "./Login";
import marketingMessages from "marketing/messages";
import UserWithoutEmail from "./UserWithoutEmail";
import convertFileToUpload from "utils/convertFileToUpload";
import Footer from "components/Footer";
import MiscApi from "api/api/MiscApi";
import products from "productList";

import AuthProvider, { withAuth } from "./Context";

const miscApi = new MiscApi();

const messages = defineMessages({
  initLoading: { "id": "marketing.auth.initLoading", "defaultMessage": "Loading your session" },



  initError: { "id": "marketing.auth.initError", "defaultMessage": "Unable to load your session" }



});
// import {withRequests} from '../Requests'

// const userApi = new UserApi()

// This component
// render a login component if user is not logged
// render children if user is logged

class Auth extends Component {
  state = {};
  componentDidMount() {
    this.event = window.addEventListener("keydown", this.onKeyDown.bind(this));
  }
  onKeyDown(e) {
    const { props, state } = this;
    const { auth = {} } = props;
    const { user = {} } = auth;

    if (user.id && e.ctrlKey && e.altKey && e.key === "o") {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ import: !state.import });
    }
  }
  render() {
    const { props, state } = this;

    const { imported = [] } = state;

    const initRequest =
    props.auth.requests.find((r) => r.id === "auth/init") || {};

    if (["done", "err"].indexOf(initRequest.status) < 0) {
      return (
        <Maintenance>
          <Loading
            message={
            initRequest.status === "pending" ?
            props.intl.formatMessage(messages.initLoading) :
            null} />


        </Maintenance>);

    }

    if (
    props.auth.user.id ||
    props.match.path === "/:lang/account" && props.match.isExact)
    {
      if (props.userMustBeLogged) {
        if (!props.auth.user.webLogin) {
          return (
            <Maintenance>
              <div>
                <div className="page">
                  <div className="container my-3">
                    <Login></Login>
                    {/* {window.location.pathname === '/'+props.intl.locale+'/account/signup'
                      ? <Signup/>
                      : <Login/>
                      } */}
                  </div>
                </div>
                <Footer></Footer>
              </div>
            </Maintenance>);

        }
        // user must validate his email address
        if (!props.auth.user.email) {
          return (
            <Maintenance>
              <div>
                <div className="page">
                  <div className="container sep-top-3x sep-bottom-lg">
                    <UserWithoutEmail />
                  </div>
                </div>
                <Footer></Footer>
              </div>
            </Maintenance>);

        }
      }
      if (state.import) {
        return (
          <Maintenance>
            <div className="p-5">
              <h1>Import</h1>
              <p>Importez un fichier emsx</p>
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  return convertFileToUpload(file).
                  then((content) => {
                    return miscApi.importData(content);
                  }).
                  then((imported) => this.setState({ imported }));
                  // .catch((e) => e)
                  // .then((e) => this.setState({ import: false }));
                }} />

              {imported.length ?
              <div className="my-3">
                  <h4>Importés</h4>
                  <ul>
                    {imported.map((o) => {
                    const link = o.type.endsWith("_quotation") ?
                    ["", o.type.slice(0, -10), o.id].join("/") :
                    false;
                    return (
                      <li>
                          {link ?
                        <a href={link}>
                              {o.type} {o.id}{" "}
                              <Glyphicon icon="external-link-alt" />
                            </a> :

                        <span>
                              {o.type} {o.id}
                            </span>}

                        </li>);

                  })}
                  </ul>
                </div> :
              null}
            </div>
          </Maintenance>);

      }

      return <Maintenance>{props.children}</Maintenance>;
    } else {
      return (
        <Maintenance>
          <ErrorMessage message={props.intl.formatMessage(messages.initError)}>
            <Button onClick={(e) => props.authActions.init()} bsStyle="primary">
              {props.intl.formatMessage(marketingMessages.retry)}
            </Button>
          </ErrorMessage>
        </Maintenance>);

    }
  }
}

export default withRouter(injectIntl(withAuth(Auth)));

const mapStateToProps = (state) => {
  const { misc = {} } = state;
  return {
    cpmMaintenance: misc.cpmMaintenance
  };
};

export const Maintenance = connect(mapStateToProps)(
  class extends React.Component {
    render() {
      const { props } = this;
      const { cpmMaintenance = [] } = props;
      return (
        <Switch>
          {[...products, "account", "cart", "signup"].map((u) => {
            return (
              <Route
                key={u}
                path={"/:lang/" + u}
                render={(nProps) =>
                cpmMaintenance.indexOf("all") >= 0 ||
                cpmMaintenance.indexOf(u) >= 0 ?
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "100vh"
                  }}>

                      <div
                    className="text-center pt-5"
                    style={{ width: "33vw", minWidth: 300, fontSize: 14 }}>

                        <h2>
                          <FormattedMessage
                        id="marketing.maintenance.title"
                        defaultMessage="Website under maintenance" />

                          <img
                        className="p-2"
                        width="150px"
                        src="/images/maintenance.png" />

                        </h2>
                        <p>
                          <FormattedMessage
                        id="marketing.maintenance.text"
                        defaultMessage="Our site is currently under maintenance. We apologize for the inconvenience caused. You can contact our customer support at sales@emsfactory.com" />



                        </p>
                      </div>
                    </div> :

                props.children} />);




          })}
          <Route render={(nProps) => props.children} />
        </Switch>);

    }
  }
);

export { AuthProvider, withAuth };