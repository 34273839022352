const React = require("react");

const blocks = [
{
  type: "P",
  check: (node) => node.tagName === "P",
  extractData: (node) => {
    return { type: "P", content: node.innerHTML };
  },
  component: ({ onReturn, content = "" }) =>
  <p dangerouslySetInnerHTML={{ __html: content }}></p>

},
{
  type: "Title",
  check: (node) => ["H1", "H2", "H3", "H4"].indexOf(node.tagName) >= 0,
  extractData: (node) => {
    const { tagName } = node;
    const level = parseInt(tagName.slice(-1));
    return { type: "Title", level, content: node.innerHTML };
  },
  component: (props) => {
    const tag = "h" + (props.level || 2);
    return React.createElement(tag, {
      dangerouslySetInnerHTML: { __html: props.content || "" },
      onClick: (e) => console.log("onClick")
    });
  }
},
{
  type: "Image",
  check: (node) => node.tagName === "FIGURE",
  extractData: (node) => {
    if (!node || !node.querySelector) {
      return {};
    }
    const nodeAttributes = node.attributes;
    const imageNode = node.querySelector("img") || {};
    const imageAttributes = imageNode.attributes;
    const captionNode = node.querySelector("figcaption.figure-caption") || {};
    const classNames = node.className.split(" ");

    let res = {
      type: "Image",
      imageAttributes,
      src: imageNode.src,
      alt: imageNode.alt,
      caption: captionNode.innerHTML,
      align:
      classNames.indexOf("float-left") >= 0 ?
      "left" :
      classNames.indexOf("float-right") >= 0 ?
      "right" :
      "center"
    };
    if (nodeAttributes.imageid) {
      res.id = nodeAttributes.imageid.value;
    }
    console.log("ContentEditor ImageBlock extractData", node, res);
    return res;
  },
  component: ({
    align = "center",
    onSelect,
    src,
    alt,
    caption,
    id,
    srcset,
    href,
    targetBlank
  }) => {
    const img =
    <img
      src={src}
      alt={alt}
      srcset={srcset}
      className="figure-img img-fluid" />;


    return (
      <figure
        contentEditable={false}
        className={
        "figure text-center" + (
        align === "left" ?
        " float-left pr-3 w-50" :
        align === "right" ?
        " float-right pl-3 w-50" :
        " w-100") +
        " mb-3"}

        style={{ clear: "both" }}
        onClick={(e) => onSelect(e)}
        onKeyDown={(e) => console.log("Image onKeyDown", e)}
        imageid={id}>

          {href ?
        <a
          href={(href.search(/https?:\/\//) < 0 ? "http://" : "") + href}
          target={targetBlank ? "_blank" : false}>

              {img}
            </a> :

        img}

          {caption ?
        <figcaption
          className="figure-caption"
          dangerouslySetInnerHTML={{ __html: caption || "Caption" }}>
        </figcaption> :
        null}
        </figure>);

  }
},
{
  type: "Video",
  check: (node) => {
    if (!node) {
      return false;
    }
    return (
      node.tagName === "DIV" &&
      node.className.split(" ").indexOf("content_block-video") >= 0);

  },
  extractData: (node) => {
    if (!node || !node.querySelector) {
      return {};
    }
    const classes = node.className.split(" ");
    const iframeNode = node.querySelector("iframe") || {};
    const styles = node.style;
    const align =
    classes.indexOf("float-left") >= 0 ?
    "left" :
    classes.indexOf("float-right") >= 0 ?
    "right" :
    "center";
    const width =
    (align !== "center" ? styles.width : iframeNode.width) || "";
    let res = {
      type: "Video",
      align,
      src: iframeNode.src,
      width: width.endsWith("%") ? width.slice(0, -1) : width
    };
    // const nodeAttributes = node.attributes;
    // const imageNode = node.querySelector("img") || {};
    // const imageAttributes = imageNode.attributes;
    // const captionNode = node.querySelector("figcaption.figure-caption") || {};
    // const classNames = node.className.split(" ");

    // let res = {
    //   type: "Image",
    //   imageAttributes,
    //   src: imageNode.src,
    //   alt: imageNode.alt,
    //   caption: captionNode.innerHTML,
    //   align:
    //     classNames.indexOf("float-left") >= 0
    //       ? "left"
    //       : classNames.indexOf("float-right") >= 0
    //       ? "right"
    //       : "center",
    // };
    // if (nodeAttributes.imageid) {
    //   res.id = nodeAttributes.imageid.value;
    // }
    console.log("ContentEditor VideoBlock extractData", node, res);
    return res;
  },
  component: ({ align = "center", onSelect, src, width = 50 }) => {
    const videoId =
    (src.match(/https:\/\/youtu.be\/([\w_-]+)/) || {})[1] ||
    (src.match(/https:\/\/www.youtube.com\/embed\/([\w_-]+)/) || {})[1] ||
    (src.match(/https:\/\/www.youtube.com\/watch\?v=([\w_-]+)/) || {})[1];
    console.log("Video render", src, videoId);
    return (
      <div
        onClick={(e) => onSelect(e)}
        className={
        "content_block-video p-3 " + (
        align === "center" ? "text-center" : "float-" + align)}

        contentEditable={false}
        style={{
          width: align === "center" ? "100%" : width + "%"
        }}>

          <iframe
          width={align === "center" ? width + "%" : "100%"}
          height="315"
          src={"https://www.youtube.com/embed/" + videoId + "?rel=0"}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
        </div>
      // <figure
      //   contentEditable={false}
      //   className={
      //     "figure text-center" +
      //     (align === "left"
      //       ? " float-left pr-3 w-50"
      //       : align === "right"
      //       ? " float-right pl-3 w-50"
      //       : " w-100") +
      //     " mb-3"
      //   }
      //   style={{ clear: "both" }}
      //   onClick={(e) => onSelect(e)}
      //   onKeyDown={(e) => console.log("Image onKeyDown", e)}
      //   imageid={id}
      // >
      //   <img
      //     src={src}
      //     alt={alt}
      //     srcset={srcset}
      //     className="figure-img img-fluid"
      //   />
      //   {caption ? (
      //     <figcaption
      //       className="figure-caption"
      //       dangerouslySetInnerHTML={{ __html: caption || "Caption" }}
      //     ></figcaption>
      //   ) : null}
      // </figure>
    );
  }
}];


module.exports = blocks;