import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import products from "marketing/productPages";
import { NavLink, Link, Glyphicon } from "Elements";
import Section from "marketing/components/Section";
import HelpTile from "marketing/components/HelpTile";
import Spinner from "components/Spinner";
import metaTags from "utils/metaTags";

import { withRequests } from "Requests";

import CmsActions from "Cms/actions";
import marketingMessages from "marketing/messages";

const messages = defineMessages({
  text: { "id": "marketing.ressources.text", "defaultMessage": "Find the articles written by our technical teams to help you better optimize your projects for production and in your technical choices" },




  searchPlaceholder: { "id": "marketing.ressources.searchPlaceholder", "defaultMessage": "What are you looking for ?" }



});

class Page extends React.Component {
  state = {
    filter: ""
  };
  componentDidMount() {
    this.update();
  }

  componentDidUpdate(oProps) {
    if (oProps.intl.locale !== this.props.intl.locale) {
      this.update();
    }
  }

  update() {
    const { props } = this;
    const language = props.intl.locale;
    props.addRequest("blog/getArticles", CmsActions.getRessources(language));
  }

  render() {
    const { props, state } = this;

    metaTags.set({
      title: [
      props.intl.formatMessage(marketingMessages.ressources),
      props.match.params.categorie].

      filter((s) => s).
      join(" "),
      description: props.intl.formatMessage(messages.text)
    });

    let articles = props.match.params.categorie ?
    (props.articles || []).filter(
      (c) => c.product === props.match.params.categorie
    ) :
    props.articles || [];

    const sortField = false;

    const filter = this.state.filter ?
    this.state.filter.
    split(" ").
    filter((f) => f).
    map((f) => {
      try {
        return new RegExp(f, "i");
      } catch (err) {
        return false;
      }
    }).
    filter((f) => f) :
    false;

    articles = articles.
    filter((article) => {
      if (!filter) {
        return true;
      }
      return (
        filter.
        map(
          (f) =>
          [article.title].map((field) => field.match(f)).filter((f) => f).
          length > 0
        ).
        filter((f) => f).length === filter.length);

    }).
    sort((a, b) => {
      if (!sortField) {
        return 0;
      }
      return a[sortField] > b[sortField] ?
      state.sortAsc ?
      1 :
      -1 :
      state.sortAsc ?
      -1 :
      1;
    });

    const request =
    props.requests.find((r) => r.id === "blog/getArticles") || {};

    console.log("ressources render", articles, articles.length);

    return (
      <div>
        <Section className=" contactcover p-0">
          <div
            className="bg-primary"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              left: "0",
              top: "0",
              opacity: "0.75"
            }}>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center pt-5">
              <div className="product_page_cover_intro text-white">
                <h1 style={{ letterSpacing: "7px" }}>
                  {props.intl.formatMessage(marketingMessages.ressources)}
                </h1>
              </div>
            </div>
            <div className="col-md-8 offset-md-2">
              <div className="pt-2 text-white text-center">
                <p className="m-0">{props.intl.formatMessage(messages.text)}</p>
              </div>
            </div>
            <div
              id="filters"
              className="light text-center py-3 pt-md-5 col-md-8 offset-md-2">

              <NavLink
                to="/ressources"
                exact
                className="btn btn-outline-secondary btn-ressources">

                <span
                  style={{
                    fontFamily: "MysteryTourSans",
                    textTransform: "lowercase"
                  }}>

                  <FormattedMessage
                    id="marketing.ressources.all"
                    defaultMessage="All" />

                </span>
              </NavLink>
              {products.map((c, i) => {
                const { IntlComponent = (props) => props.children(c) } = c;
                return (
                  <IntlComponent key={i}>
                    {(c) =>
                    <NavLink
                      to={"/ressources/" + c.slug}
                      className={
                      "btn btn-outline-secondary btn-ressources m-1 m-md-2"}>


                        <span
                        style={{
                          fontFamily: "MysteryTourSans",
                          textTransform: "lowercase"
                        }}>

                          {c.title}
                        </span>
                      </NavLink>}

                  </IntlComponent>);

              })}
            </div>
          </div>
        </Section>
        <div className="d-flex justify-content-center">
          <div className="input-group ressources_input">
            <div className="input-group-prepend">
              <span
                className="input-group-text text-white bg-secondary pl-3"
                style={{
                  border: "1px solid #ec7b51",
                  fontSize: "25px",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "10px"
                }}>

                <Glyphicon icon="search" />
              </span>
            </div>
            <input
              type="text"
              className="form-control text-white bg-secondary border-0"
              style={{
                // border: "1px solid #ec7b51",
                height: "50px"
              }}
              placeholder={props.intl.formatMessage(messages.searchPlaceholder)}
              onChange={(e) => this.setState({ filter: e.target.value })} />

            <div className="input-group-append">
              <span
                className="input-group-text text-white bg-secondary pr-3"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ec7b51",
                  fontFamily: "MysteryTourSans",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "10px"
                }}>

                {props.intl.formatMessage(marketingMessages.ok)}
              </span>
            </div>
          </div>
        </div>
        <Section>
          <div className="row">
            {request.status === "pending" ?
            <div className="col-12 text-center">
                <Spinner />
              </div> :
            !articles.length ?
            <div className="col-10 offset-1 text-muted text-center">
                <span>
                  <FormattedMessage
                  id="marketing.ressources.noArticles"
                  defaultMessage="No ressource have been found" />

                </span>
              </div> :

            <div className="col-md-10 offset-md-1">
                {articles.map((article, i) =>
              <RessourceLink article={article} key={i} />
              )}
              </div>}

          </div>
        </Section>
        <HelpTile />
      </div>);

  }
}

const RessourceLink = ({ article, lang }) => {
  const { cover = {} } = article;
  const { sizes = {} } = cover;
  console.log("REssourceLink render", article, lang);
  return (
    <div className="my-lg-3 my-2">
      <Link
        to={
        "/ressources/" +
        article.product +
        "/" +
        article.slug +
        "/" +
        article.id}

        style={{ textDecoration: "none" }}
        lang={lang}>

        <div className="d-flex flex-wrap justify-content-center ressources_card_all">
          <div
            className="pl-md-0 py-3 py-md-0 bg-black-10 d-flex justify-content-center align-items-center"
            style={{ width: 200, height: 200, overflow: "hidden" }}>

            <img
              data-src={cover.preview}
              alt={cover.alt}
              className="ressources_card__img lazyload" />

          </div>
          <div className="col-10 col-md">
            <div className="row d-flex justify-content-between ressources_card">
              <span className="col-9">{article.title}</span>
              <div
                className={
                "ressources_card_color-" + article.product + " text-white"}

                style={{ height: "25px" }}>

                {article.product}
              </div>
            </div>
            <div className="mt-3">
              <p className="ressources_intro">{article.desc}</p>
            </div>
          </div>
          <div
            className="ressources_arrow d-flex align-items-center justify-content-center text-white"
            style={{ width: "50px", fontSize: "30px" }}>

            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </Link>
    </div>);

};

const mapStateToProps = (state) => {
  return { articles: state.cms.ressources };
};

export default withRequests(connect(mapStateToProps)(injectIntl(Page)));

export { RessourceLink };