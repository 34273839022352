import React from "react";

const Spinner = (props) =>
<i
  className={
  "fas fa-spinner fa-pulse" + (
  props.size ? " fa-" + props.size : "") + (
  props.className ? " " + props.className : "")}>

</i>;


export default Spinner;