import React from "react";
import {
  FormattedMessage,
  // FormattedHTMLMessage,
  defineMessages,
  injectIntl } from
"react-intl";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  SimpleModal,
  Button
  // Glyphicon,
  // HelpBlock,
} from "Elements";

import { withAuth } from "./Context";

import Spinner from "components/Spinner";
import marketingMessages from "marketing/messages";
const messages = defineMessages({
  doneMessage: { "id": "marketing.doneMessage", "defaultMessage": "An email has been sent to you. Please follow the instructions in this email. If you do not receive this email or have problems, please contact us via the chat or the contact form." }




});

class UserPasswordRequest extends React.Component {
  state = {};
  // componentDidUpdate(oProps) {
  //   if (!this.props.show && oProps.show) {
  //     requestsActions.remove("auth/userPasswordRequest");
  //   }
  // }
  render() {
    const { props, state } = this;
    console.log("UserPasswordRequest render", props, state);
    return (
      <SimpleModal
        onHide={props.onHide}
        header={
        <FormattedMessage
          id="marketing.auth.pwdRequest.forgottenPwd"
          defaultMessage="Forgotten password" />}


        footer={
        <div>
            <Button type="button" onClick={props.onHide}>
              {props.intl.formatMessage(marketingMessages.close)}
            </Button>
            {!state.request || state.request === "err" ?
          <Button bsStyle="primary" className="ml-1">
                {props.intl.formatMessage(marketingMessages.send)}
              </Button> :
          null}
          </div>}

        onSubmit={(e) => {
          this.setState({ request: "pending" });
          props.authActions.
          userPasswordRequest(state.forgottenWeblogin).
          then((e) => this.setState({ request: "done" })).
          catch((e) => {
            console.log("UserPasswordRequest onSubmit error", e);
            this.setState({ request: "err" });
          });
        }}>

        {["done", "pending"].indexOf(state.request) >= 0 ?
        state.request === "pending" ?
        <div className="text-center p-5">
              <Spinner />
            </div> :

        <div className="alert alert-success">
              {props.intl.formatMessage(messages.doneMessage)}
            </div> :


        <div>
            <div className="alert alert-info">
              <FormattedMessage
              id="marketing.auth.pwdRequest.info"
              defaultMessage="You can regenerate your password at any time, using your login, in case of problem contact us via the chat or the contact form." />

            </div>
            <FormGroup
          // validationState={state.request === "err" ? "error" : null}
          >
              <ControlLabel>
                {props.intl.formatMessage(marketingMessages.loginId)}
              </ControlLabel>
              <FormControl
              onChange={(e) => {
                this.setState({
                  forgottenWeblogin: e.target.value
                });
              }}
              value={state.forgottenWeblogin}
              placeholder={props.intl.formatMessage(
                marketingMessages.loginId
              )} />

              {state.request === "err" ?
            <div className="alert alert-danger mt-3">
                  {props.intl.formatMessage(
                marketingMessages.webLoginInvalidErr
              )}
                </div> :
            null}
            </FormGroup>
          </div>}

      </SimpleModal>);

  }
}

export default injectIntl(withAuth(UserPasswordRequest));