import React from "react";
import {
  defineMessages,
  injectIntl,
  FormattedDate,
  FormattedMessage } from
"react-intl";
import { Link } from "Elements";
import Section from "marketing/components/Section";
import marketingMessages from "../messages";

const messages = defineMessages({
  LirePlus: { "id": "marketing.blog.LirePlus", "defaultMessage": "Read more" }



});

class MoreArticle extends React.Component {
  render() {
    const { props } = this;

    const {
      prefix,
      articles = [],
      id,
      categoryField = "category",
      category
    } = props;
    const moreArticles = articles.
    filter(
      (a) => a.id !== id && (category ? a[categoryField] === category : true)
    ).
    slice(0, 4);

    console.log("MoreArticles render", props, articles, moreArticles.length);

    if (!moreArticles.length) {
      return null;
    } else {
      return (
        <Section
          className=""
          title={props.intl.formatMessage(marketingMessages.lastArticle)}>

          <ArticleDeck
            articles={moreArticles}
            prefix={prefix}
            categoryField={categoryField} />

        </Section>);

    }
  }
}

export default injectIntl(MoreArticle);

export const ArticleDeck = injectIntl((props) => {
  const { articles = [], prefix, categoryField = "category" } = props;
  return (
    <div className="blog_blogs">
      {articles.length ?
      articles.map((article, i) => {
        const { cover = {}, date } = article;
        // const category =
        //   categories.find((c) => c.slug === article.category) || {};
        return (
          <div className="blog_blogs_blog">
              <div className="card p-0 my-2 link_decoration" key={i}>
                <Link
              // to={
              //   "/blog/" +
              //   article.category +
              //   "/" +
              //   article.slug +
              //   "/" +
              //   article.id
              // }
              to={[prefix, article[categoryField], article.slug, article.id].
              filter((s) => s).
              join("/")}>

                  <div className="blog_card__img_wrapper">
                    {/* {category ? (
                     <div className="img_category">
                       <div className="blog_category">
                         <span className="text-white">{category.title}</span>
                       </div>
                     </div>
                    ) : null} */}
                    {date ?
                  <div
                    className="bg-primary p-1 text-white"
                    style={{
                      position: "absolute",
                      top: 155,
                      left: 0
                    }}>

                        <FormattedDate value={new Date(date)} />
                      </div> :
                  null}

                    <img
                    data-src={cover.preview}
                    // data-srcset={cover.srcset}
                    // data-sizes="198px"
                    alt={cover.alt}
                    className="blog_card__img lazyload" />

                    {/* <img
                    src={cover.preview}
                    alt={cover.alt}
                    className="blog_card__img"
                    /> */}
                  </div>
                  <div
                  className="card-body blog_content px-2"
                  style={{ height: 215 }}>

                    <span className="card-title">{article.title}</span>
                    <p className="mt-2 card-texte text-black-70">
                      {article.desc}
                    </p>
                  </div>
                  <div style={{ backgroundColor: "#eeeeee" }}>
                    <div
                    className="d-flex justify-content-end col-10 offset-1 pr-0"
                    style={{ borderTop: "1px solid #a8a9a9" }}>

                      <span
                      className="ressources_category pt-1"
                      style={{ color: "#343a40" }}>

                        {props.intl.formatMessage(messages.LirePlus)}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>);

      }) :

      <div className="col-10 offset-1 text-muted text-center">
          <span>
            <FormattedMessage
            id="marketing.blog.noArticles"
            defaultMessage="No article to display" />

          </span>
        </div>}

    </div>);

  return (
    <div className="row justify-content-center">
      {articles.map((c, i) => {
        const { cover = {} } = c;
        return (
          <div className="col-md-4 my-3" key={i}>
            <Link
              to={[prefix, c[categoryField], c.slug, c.id].
              filter((s) => s).
              join("/")}>

              <div
                className="card"
                style={{
                  backgroundColor: "#e9ecef",
                  overflow: "hidden"
                }}>

                <img
                  src={cover.src}
                  srcset={cover.srcset}
                  alt={cover.alt}
                  className="card-img-top" />

                <div className="card-body" style={{ height: "200px" }}>
                  <strong className=" ressources_card_product">
                    {c.title}
                  </strong>
                  <p
                    className="card-texte ressources_card__text text-center text-black-70"
                    style={{ fontSize: "13px" }}>

                    {c.desc}
                  </p>
                </div>
              </div>
            </Link>
          </div>);

      })}
    </div>);

});