import React from "react";
import { connect } from "react-redux";

import { injectIntl, FormattedMessage } from "react-intl";
import HelpTile from "marketing/components/HelpTile";
import { Link, Glyphicon } from "Elements";
import MoreArticle from "marketing/components/MoreArticle";
import PrevAndNext from "marketing/components/PrevAndNext";
import StartProject from "marketing/components/StartProject";
import Spinner from "components/Spinner";
import Page404 from "components/404";
import ShareOnSN from "components/ShareOnSN";
import { RessourceLink } from "./index";

import { withRequests } from "Requests";

import cmsActions from "Cms/actions";
import languages from "Intl/languages";

import Content from "components/ContentEditor/Content";
import metaTags from "utils/metaTags";
import marketingMessages from "marketing/messages";

class Page extends React.Component {
  componentDidMount() {
    this.update();
  }

  componentDidUpdate(oProps) {
    if (
    oProps.match.params.id !== this.props.match.params.id ||
    oProps.intl.locale !== this.props.intl.locale)
    {
      this.update();
    }
  }

  update() {
    const { props } = this;
    const language = props.intl.locale;
    const id = props.match.params.id;
    cmsActions.getRessources(language).then((res) => {
      cmsActions.getRessourcesArticle(language, id);
    });
  }

  render() {
    //const { props, state } = this;
    const { props } = this;
    const id = props.match.params.id;
    const { articles = [] } = props;

    if (articles.length) {
      var prev = {};
      var next = {};
      articles.forEach((ind, a) => {
        if (ind.id === id) {
          if (a === 0) {
            prev = {};
            next = articles[a + 1];
          } else if (a === articles.length - 1) {
            prev = articles[a - 1];
            next = {};
          } else {
            prev = articles[a - 1];
            next = articles[a + 1];
          }
        }
      });
    }
    prev = (prev || {}).id ? prev : {};
    next = (next || {}).id ? next : {};

    const request =
    props.requests.find((r) => r.id === "ressources/getArticle") || {};

    if (request.status === "pending") {
      return <Spinner />;
    } else {
      const article = props.articles.find((r) => r.id === id) || false;
      if (!article) {
        return <Page404 />;
      }
      const { content, cover = {}, files = [] } = article;
      const translations = (article.translations || []).filter(
        (t) =>
        [props.intl.locale, article.realLang].
        filter((s) => s).
        indexOf(t.lang) < 0
      );
      const url =
      "https://emsfactory.com/ressources/" +
      article.product +
      "/" +
      article.slug +
      "/" +
      article.id;
      console.log("ressourceArticle render", article);
      metaTags.set(article);
      return (
        <div className="ressources_article">
          {content ?
          <section className="container mt-5 pt-4">
              <div className="row mb-5">
                <div className="col-md-10 offset-md-1 ">
                  <div className="d-fex flex-column">
                    <div className="fil_ariane pt-4">
                      <Link to="/ressources/">
                        {props.intl.formatMessage(marketingMessages.ressources)}
                      </Link>
                      &nbsp;
                      <span>/</span>
                      <Link to={"/ressources/" + article.product + "/"}>
                        {article.product}
                      </Link>
                      <span>/</span>
                      <span>{article.title}</span>
                    </div>
                    <div className="pt-2 d-flex flex-column">
                      <strong className="py-2" style={{ fontSize: "30px" }}>
                        {article.title}
                      </strong>
                      <span
                      className="py-2"
                      style={{ fontWeight: "450", fontSize: "25px" }}>

                        {article.desc}
                      </span>
                    </div>
                  </div>
                  <div className="text-center bg-black-10">
                    <img
                    src={cover.src}
                    alt={cover.alt}
                    srcset={cover.srcset}
                    className="py-3 img-fluid"
                    style={{ maxHeight: "60vh" }}
                    sizes="
                      (min-width:576px) 410px,
                      (min-width:768px) 570px, 
                      (min-width:992px) 770px, 
                      (min-width:1200px) 919px,
                      " />






                  </div>
                  {/* <ShareOnSN url={url} /> */}
                  <div className="mb-3 mt-5">
                    {article.realLang ?
                  <FormattedMessage
                    id="marketing.ressources.notLocalisedInfo"
                    defaultMessage="This article has not yet been translated into your language." /> :

                  null}
                    {translations.length ?
                  <div>
                        <FormattedMessage
                      id="marketing.ressources.localeVersions"
                      defaultMessage="This article is available in the following languages :" />

                        {translations.map((t) =>
                    <Link
                      to={
                      "/ressources/" +
                      article.product +
                      "/" +
                      t.slug +
                      "/" +
                      article.id}

                      lang={t.lang}
                      className="mx-3">

                            {languages[t.lang]}
                          </Link>
                    )}
                      </div> :
                  null}
                  </div>

                  <div className="cms_content my-3" />
                  <Content blocks={content} />
                </div>
              </div>
              {files.length ?
            <div className="col-md-8 offset-md-2 ressources_dl py-3">
                  <strong style={{ fontSize: "20px" }}>
                    <FormattedMessage
                  id="marketing.ressources.Fichiers"
                  defaultMessage="Files" />

                  </strong>
                  <div className="row">
                    {files.map((file, i) =>
                <a
                  target="_blank"
                  href={
                  "/content/ressources/" +
                  props.intl.locale +
                  "/" +
                  article.id +
                  "/files/" +
                  file}

                  className="d-flex align-items-center flex-column col-md-3 my-3">

                        <Glyphicon glyph="file" lib="fa" size="3x" />
                        {file}
                      </a>
                )}
                  </div>
                </div> :
            null}
              {content.pdf ?
            <div className="row mt-5 ressources_pdf">
                  <div className="col-2 offset-5">
                    <a
                  className="d-flex align-items-center flex-column"
                  target="_blank"
                  href={content.pdf}>

                      <img src="/icons/download.svg" />
                      <FormattedMessage
                    id="marketing.ressources.TelechargeArticle"
                    defaultMessage="Download the article" />

                    </a>
                  </div>
                </div> :
            null}
              <div className="my-3">
                <ShareOnSN url={url} />
              </div>
              <hr />
              <PrevAndNext
              prefix="ressources"
              categoryField="product"
              category={article.product}
              id={article.id}
              list={articles} />


              <MoreArticle
              prefix="ressources"
              categoryField="product"
              id={article.id}
              articles={articles} />

            </section> :

          <section className="container mt-5 pt-4">
              <FormattedMessage
              id="marketing.ressources.notLocalised"
              defaultMessage="Article not translated"
              tagName="h1" />

              <p className="lead">
                <FormattedMessage
                id="marketing.ressources.notLocalisedInfo"
                defaultMessage="This article has not yet been translated into your language." />

              </p>
              <div className="row">
                <div className="col-8 offset-2">
                  {translations.map((t, i) =>
                <div key={i} className="my-4">
                      <h4>{languages[t.lang]}</h4>
                      <RessourceLink
                    article={Object.assign({}, article, t)}
                    lang={t.lang} />

                    </div>
                )}
                </div>
              </div>
              <MoreArticle
              product={article.product}
              id={article.id}
              articles={articles} />

            </section>}

          <StartProject />
          <HelpTile />
        </div>);

    }
  }
}

const mapStateToProps = (state) => {
  return { articles: state.cms.ressources };
};

export default withRequests(connect(mapStateToProps)(injectIntl(Page)));