import React from "react";
// import NavLink from './NavLink'
import { NavLink, Glyphicon } from ".";
import { defineMessages } from "react-intl";
class NavDropdown extends React.Component {
  state = {};
  toggle() {
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    const { props, state } = this;
    const {
      dropdownStyle = {},
      dropdownClassName = "",
      baseClassName = "nav-item",
      items = []
    } = props;
    const directionClassName = props.direction === "up" ? "dropup" : "dropdown";
    // const baseClassName = props.className || "nav-item";
    // console.log('navdropdown render', props)
    return (
      <div className={[baseClassName, directionClassName].join(" ")}>
        <div
          style={{
            background: "#000",
            opacity: 0,
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: state.open ? "inherit" : "none"
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.toggle();
          }}>
        </div>
        <div
        // to="/"
        className={
        "pointer " + (
        props.noCaret ? "" : "dropdown-toggle ") + (
        props.className || "")}

        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.toggle();
        }}>

          {props.title}
        </div>
        <div
          className={[
          "dropdown-menu",
          state.open ? "show" : "",
          dropdownClassName].

          filter((e) => e).
          join(" ")}
          style={Object.assign({}, dropdownStyle, {
            top: "initial",
            left: "initial"
          })}>

          {items.map((item, i) =>
          <NavLink
            exact={item.exact}
            activeClassName={props.activeClassName}
            // lang={item.lang}
            className={"dropdown-item " + item}
            key={i}
            to={item.href}
            onClick={(e) => {
              this.toggle();
              if (item.onClick) {
                item.onClick(e);
              }
            }}>

              {item.icon ?
            <Glyphicon icon={item.icon} className="mr-1" /> :
            null}
              <span>{item.title}</span>
            </NavLink>
          )}
        </div>
      </div>);

  }
}

export default NavDropdown;