import React from "react";
import { injectIntl, defineMessages } from "react-intl";

import { NavHashLink } from "react-router-hash-link";
import GalleryPreview from "components/GalleryPreview";
import Section from "marketing/components/Section";

class Map extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { images = [], text } = data;
    console.log("Map render", data);
    return (
      <Section
        noContainer
        className="map lazyload"
        data-bg="/images/marketing/map.svg"
        // bgImage="http://www.smt-china.com/images/oktek_okano_smt_pcba_machine1.jpg"
      >
        <svg
          className="footer__top"
          viewBox="0 0 100 10"
          preserveAspectRatio="none">

          <path d="M0 0L0 10L100 10L100 0Q50 20, 0 0z" />
        </svg>
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-5 offset-lg-1 col-xl-4 d-flex justify-content-end">
          <div className="map__gallery">
            <GalleryPreview images={images} />
          </div>
        </div>
        {text ?
        <div className="col-sm-10 offset-sm-1 lg-col offset-md-0 map__text">
            <p>{text}</p>
          </div> :
        null}
        {props.contactezNous ?
        <div className="map_contactezNous d-flex flex-column justify-content-between">
            <h4>{props.findUs}</h4>
            <div className="d-flex flex-column align-items-end">
              <span>EMS FACTORY</span>
              <span>10 allée Pierre-Gilles de Gennes</span>
              <span>33650 Martillac</span>
              <span>France</span>
            </div>
            <strong className="pt-4">{props.openHours}</strong>
          </div> :
        null}
        {props.contact ?
        <div className="map_contact">
            <NavHashLink
            to="/contact#map"
            className="btn btn-outline-primary map_btn"
            scroll={(el) =>
            el.scrollIntoView({ behavior: "instant", block: "center" })}>


              {props.contact}
            </NavHashLink>
          </div> :
        null}
      </Section>);

  }
}

export default injectIntl(Map);