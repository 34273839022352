import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import { ButtonGroup, Glyphicon, Button, Link } from "Elements";
import helpActions from "Help/actions";
import messagingActions from "Messaging/actions";

import NewProject from "components/NewProject";
import marketingMessages from "marketing/messages";
class HeaderHelp extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    console.log("Messaging HeaderHelp render", props);
    return [
    <a
      key="0"
      href="#"
      className="bg-secondary text-white d-none d-lg-block"
      onClick={(e) => {
        e.preventDefault();
        this.setState({ showNewProject: true });
      }}>

        <Glyphicon glyph="plus" className="icon mr-xxl-2" />
        <FormattedMessage {...marketingMessages.createProject}>
          {(txt) => <span className="d-none d-xxl-inline">{txt}</span>}
        </FormattedMessage>
        {state.showNewProject ?
      <NewProject
        text={props.intl.formatMessage(marketingMessages.selectService)}
        modal
        productLink="/start"
        onHide={(e) =>
        this.setState({
          showNewProject: false
        })} /> :


      null}
      </a>,
    <a
      key="1"
      href="#"
      className="bg-primaryDark text-secondary d-none d-sm-block"
      onClick={(e) => {
        e.preventDefault();
        helpActions.show();
      }}>

        <Glyphicon glyph="question-circle" className="icon mr-lg-2" />{" "}
        <FormattedMessage id="marketing.aide.header" defaultMessage="Help">
          {(txt) => <span className="d-none d-lg-inline">{txt}</span>}
        </FormattedMessage>
      </a>,
    <a
      key="2"
      href="#"
      className="bg-primaryDark text-secondary"
      onClick={(e) => {
        e.preventDefault();
        messagingActions.show();
      }}>

        <div
        style={{
          position: "relative"
        }}>

          <Glyphicon glyph="comment-dots" className="icon mr-lg-2" />
          {props.messageUnread ?
        <div
          style={{
            backgroundColor: "red",
            width: 6,
            height: 6,
            borderRadius: 3,
            position: "absolute",
            left: 20,
            top: 20
          }}>
        </div> :
        null}
          <FormattedMessage
          {...marketingMessages.chat}
          className="d-none d-lg-inline">

            {(txt) => <span className="d-none d-lg-inline">{txt}</span>}
          </FormattedMessage>
        </div>
      </a>];

    // <div style={{
    //   display: 'flex',
    //   alignItems: 'center'
    // }}>
    //   <ButtonGroup>
    //     {props.hideHelp
    //       ? null
    //       : <Button bsStyle="info" size="lg" onClick={e => helpActions.show()}>
    //         <Glyphicon glyph="question" />
    //       </Button>
    //     }
    //   </ButtonGroup>
    // </div>
  }
}

const mapStateToProps = (state) => {
  const { messaging = {} } = state;
  const { thread = {} } = messaging;
  return {
    messageUnread: thread.unread
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(HeaderHelp)));